

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active(value) {
            if (value) {
                this.getCountries();
            }
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        job: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {

    },
    data() {
        return {
            countries: [],
        };
    },
    methods: {
        getCountries() {
            this.get('countries').then(({ data }) => {
                this.countries = data.data;
                this.job.country = this.countries[0];
            });
        },
    },

});
