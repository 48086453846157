

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active() {
        },
    },
    data() {
        return {
            types: [
                { value: 'professional', name: 'Professional', icon: 'building' },
                { value: 'private', name: 'Private', icon: 'user' },
            ],
        };
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        job: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {

    },

});
