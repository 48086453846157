

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active(value) {
            if (value) {
                this.getProducts();
            }
        },
    },
    computed: {
        filteredProducts() {
            return this.products.data.filter((product) => this.job.items.findIndex((jobItem) => jobItem.product.id === product.id) === -1);
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        job: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {

    },
    data() {
        return {
            selectedJobItem: undefined,
            currentProductPage: 1,
            products: {
                data: [],
                meta: {},
            },
            productsParams: {
                filter: '',
                page: 1,
                sortBy: 'created_at',
                orderBy: 'desc',
                type: 'product',
                parent_product: undefined,
                client: undefined,
            },
        };
    },
    methods: {
        addQuantity(jobItem) {
            jobItem.quantity++;
        },
        removeQuantity(jobItem) {
            if (jobItem.quantity > 1) {
                jobItem.quantity--;
            }
        },
        resetParams() {
            this.productsParams.filter = '';
            this.productsParams.tags = [];
        },
        addJobItem(product) {
            const jobItem = {
                product,
                quantity: 1,
                objects: [],
            };

            this.job.items.push(jobItem);
        },
        removeJobItem(jobItem) {
            const index = this.job.items.indexOf(jobItem);
            if (index !== -1) {
                this.job.items.splice(index, 1);
            }

            this.selectedJobItem = undefined;
        },
        getProducts(page = 1) {
            this.productsParams.page = page;

            this.products.data = [];

            this.get('products', {
                params: {
                    ...this.productsParams,
                    parent_product_id: this.productsParams.parent_product ? this.productsParams.parent_product.id : null,
                    client_id: this.job.client ? this.job.client.id : null,
                    tags: this.productsParams.tags.map((tag) => tag.id),
                },
            }).then(({ data }) => {
                this.products = data;
            });
        },
    },

});
