
import { IClient } from '@/interfaces/client';
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import Country from '@/pages/jobs/partials/Country.vue';
import PrivateProfessional from '@/pages/jobs/partials/PrivateProfessional.vue';
import Contact from '@/pages/jobs/partials/Contact.vue';
import EndSummary from '@/pages/jobs/partials/Summary.vue';
import Client from '@/pages/jobs/partials/Client.vue';
import Invoice from '@/pages/jobs/partials/Invoice.vue';
import Products from '@/pages/jobs/partials/Products.vue';
import Location from '@/pages/jobs/partials/Location.vue';
import { ICountry } from '@/interfaces/country';

export default defineComponent({
    components: {
        Location,
        EndSummary,
        Contact,
        PrivateProfessional,
        Products,
        Country,
        Client,
        Invoice,
    },
    mixins: [Base],
    emits: ['reload'],
    data() {
        return {
            activeTab: null,
            job: {
                location: {
                    street: '',
                },
                contact: {
                    language: undefined,
                    type: 'client',
                    name: '',
                    email: '',
                    phone: '',
                    address: {},
                },
                billing: {
                    type: 'client',
                    email: '',
                    phone: '',
                    name: '',
                    vat: '',
                    extra_infos: '',
                    po_number: '',
                    address: {},
                    commission: 'include',
                },
                name: '',
                private_professional: 'professional',
                country: undefined as any | ICountry,
                client: undefined as any | IClient,
                items: [],
            },
        };
    },
    mounted() {
        this.activeTab = 'country';
    },

    methods: {

        createItem(): void {
            this.post('jobs', {
                ...this.job,
                client_id: this.job.client ? this.job.client.id : null,
            }).then(() => {
                this.$router.push({ name: 'jobs.list' });
                this.$emit('reload');
            });
        },

    },
});
