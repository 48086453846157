

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import Languages from '@/components/TField.vue';
import AddressForm from '@/components/AddressForm.vue';

export default defineComponent({
    components: { AddressForm, Languages },
    mixins: [Base],
    watch: {
        active() {
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        job: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {

    },
    data() {
        return {
        };
    },

});
