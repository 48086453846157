

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import BuildingTypes from '@/components/BuildingTypes.vue';

export default defineComponent({
    components: { BuildingTypes },
    mixins: [Base],
    watch: {
        active() {
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        job: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {

    },
    data() {
        return {
        };
    },

});
